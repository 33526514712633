body {
  margin: 0 !important;
   font-family: "Gilroy-Medium" !important;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0px !important;
  overflow: scroll !important;
}


@font-face {
  font-family: "Gilroy-Medium" !important;
  font-weight: 100;
  src: url("../src/assets/styles/Gilroy-Medium.ttf") format("truetype");
}



