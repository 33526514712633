$base-color: #0056bb;
$clickable: #304ffe;
$background-color: #f5f5f5;

html,
body {
  font-family: "Gilroy-Medium";
}
.dropdown-toggle::after {
  content: none;
}

a:hover {
  text-decoration: none;
}
.illustrative {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0000006b;
  color: #fff;
  text-align: center;
  padding: 12px;
}
:root {
  --transition: 0.4s;
  --bg-light-1: #eef5f9;
  font-size: 0.75rem;
}
.n-padding {
  padding: 15px 20px;
}
.center {
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #007bff;
}
.tooltip-inner {
  background-color: #007bff;
  font-size: 16px;
}
.green {
  color: green;
}
.background-1 {
  background: var(--bg-light-1);
}
.bg-gray {
  background: #f5f5f5;
  padding: 0px 3rem;
}
.bg-white {
  background: #ffffff;
  padding: 0px 3rem;
}
.padd-bottm-10 {
  padding-bottom: 10px;
}
.active {
  color: $base-color !important;
}
.table {
  border-radius: 8px 8px 0px 0px;
  background: #fff;
}
.table th,
td {
  padding: 15px !important;
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.table th {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  border-bottom-width: 0px !important;
}

.vertical_divider {
  border: 0.5px solid #dfdfdf;
  margin: 15px 0px;
  height: 100%;
}
.btn.active-view {
  background: #26a8f6;
  border-radius: 4px;
  color: #fff;
}
.text {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
}
.btn {
  background: #ffffff;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 9px 16px;
  color: #0f059e;
  margin: 5px 0px;
}
.new-recommendation .btn {
  padding: 4px 10px !important;
}
.hide-filter-row.btn {
  margin-right: 15px;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
.btn-apply {
  background: #0f059e !important;
  border-radius: 4px;
  color: #ffffff !important;
}
.btn-disabled-apply {
  background: #dfdfdf !important;
  border-radius: 4px;
  pointer-events: none;
  color: #747474 !important;
}

.btn-delete {
  background: #fff;
  border-radius: 4px;
  color: #de3f4a;
}
.tile-list {
  margin-top: -1px;
  padding: 15px;
  border: 0px solid #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #fff;
  .col-3 {
    max-height: 140px;
  }
}
.tile-wrapper {
  padding: 15px;
}
.tile {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 10px;
  color: #303030;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  a {
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
  }
}
.tile-name {
  font-size: 18px;
  line-height: 33px;
  text-align: left;
}
.desc {
  text-align: left;
}

.tile-wrapper:hover,
.tile-wrapper:focus {
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.tile:hover .action-btn-row,
.tile:focus .action-btn-row,
.tile:active .action-btn-row {
  display: block;
  opacity: 1;
}
.tile:hover,
.tile:focus {
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}
.title-subtext {
  display: flex;
  justify-content: flex-end;
  color: $base-color;
}
.btn-disabled-clear-all,
.btn-disabled-clear-all:hover,
.btn-disabled-clear-all:hover:focus {
  outline: 0;
  box-shadow: none !important;
  color: #dfdfdf;
  background: #fff;
  border: none !important;
  pointer-events: none;
}
.width-90 {
  max-width: 98%;
  margin: auto;
}

.checkbox {
  height: 30px !important;
  width: 20px !important;
}
.bg-grey-1 {
  margin-top: 30px;
  border-radius: 4px;
}
.default-top {
  margin-top: 70px;
}
.align-baseline {
  align-items: baseline;
}
.content-area {
  background: #ffffff;
  padding-top: 28px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  position: relative;
}
.builder-border-default {
  /*border: 2px dashed #272798; */
  max-width: 98%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  background: #f3f3f3;
}
.default-border {
  min-height: 100px;
  border: 2px dashed #27279840;
  margin: 0 !important;
  margin-top: 15px;
  margin-bottom: 15px;
  background: #fff;
  transition: var(--transition);
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  resize: both;
  overflow: auto;
  /* padding: 15px; */
  padding-left: 15px;
  padding-right: 15px;
}
.col-default-border {
  min-height: 100px;
  border: 2px dashed #607d8b;
  padding: 20px;
  background: #fff;
  transition: var(--transition);
  position: relative;
  resize: both;
  overflow: auto;
}

.height_100 {
  height: 95%;
}
.height_max {
  margin-bottom: 20px;
  max-height: -webkit-fill-available;
  height: 100%;
  /*   overflow: hidden;*/
}

.horizontal_divider {
  border: 0.5px solid #c4c4c4;
  border-radius: 2px;
  margin: 5px 0px;
  width: 100%;
  background: #c4c4c4;
}

/* Top Navigation bar */
.top-nav {
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  height: 70px;
  z-index: 600 !important;
}
.top-nav .nav-link {
  color: #444;
  font-weight: 600;
  text-align: center;
  margin: 2px;
  line-height: 53px;
  font-weight: bold;
  font-size: 18px;
  padding: 8px 15px !important;
}
.top-nav .nav-link:hover,
.top-nav .nav-link:focus,
.top-nav .nav-link:active,
.top-nav .nav-link.active {
  color: $base-color;
  border-bottom: 3px solid $base-color !important;
}
.top-nav .align-right-auto {
  left: auto;
  right: 0;
}

.top-nav .navbar-nav li {
  position: relative;
}
#navbar {
  .project_subproject_name {
    background: none;
    border: none;
    font-size: 18px;
    height: 50px;
    padding: 5px 15px;
    color: #303030;
  }
  .btn-light.focus,
  .btn-light:focus {
    box-shadow: none;
    background-color: none;
  }
  .dropdown-divider {
    margin: 15px 0;
  }

  .dropdown-item {
    padding: 8px 20px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    box-sizing: border-box;
    color: #303030;
    user-select: none;
    display: flex;
    align-items: center;
  }
  .dropdown-item.allProjects {
    color: $base-color;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    text-decoration: none;
    background-color: #f8f9fa;
  }
}
#project_name:focus {
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.top-nav .nav-link.dropdown-toggle:after {
  display: none;
}
#change-project {
  cursor: pointer;
}

.top-nav .nav-link.dropdown-toggle.change-project:after {
  display: inline-block;
}
.top-nav .nav-link.dropdown-toggle {
  cursor: pointer;
}

.top-nav .nav-link.dropdown-toggle.change-project {
  color: #fff;
  background: $base-color;
  border-radius: 10px;
  padding: 4px;
  padding-right: 10px;
  padding-left: 10px;
  border-color: $base-color;
}

.top-nav .dropdown-item {
  cursor: pointer;
}
.top-nav .project {
  margin-left: 14px;
  font-family: Gilroy-Medium;
  display: inline-block;
  font-size: 28px;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.top-nav .rotating {
  font-size: 21px;
  transform: rotate(135deg);
}

.top-nav .brand-logo {
  max-width: 100px;
  max-height: 26px;
  padding-right: 4px;
  padding-left: 4px;
  border-right: 2px solid #ffffff4a;
}
.top-nav .brand-name {
  padding-right: 4px;
  padding-left: 8px;
  font-size: 14px;
}
.top-nav .dropdown-menu {
  font-family: gilroy-medium;
}
.top-nav .dropdown-menu .dropdown-item i {
  padding-right: 6px;
}

@media (min-width: 0px) {
  .top-nav.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

/* Top Navigation ENds */

.dasboard-sub-header {
  padding: 15px;
  margin-top: 80px;
}
.dasboard-sub-header ul {
  font-weight: 700;
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}
.dasboard-sub-header ul li {
  display: inline-block;

  cursor: pointer;
  transition: var(--transition);
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
}

.dasboard-sub-header ul li:hover,
.dasboard-sub-header ul li:focus,
.dasboard-sub-header ul li.active {
  background: $base-color !important;
  color: #fff !important;
  padding: 10px;
  border-radius: 5px;
}
.dasboard-sub-header .content-right {
  display: flex;
  align-items: center;
  max-width: 650px;
  margin-left: auto;
}
.dasboard-sub-header .content-right input.form-control {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  padding-left: 4px;
  color: #000;
  font-family: Gilroy-Medium;
  box-shadow: none;
  transition: var(--transition);
  background-color: transparent;
  color: #753bbc;
}
.dasboard-sub-header .content-right .input-group-text {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  cursor: pointer;
  transition: var(--transition);
}
.dasboard-sub-header .content-right .input-group-text:hover,
.dasboard-sub-header .content-right .input-group-text:focus {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #0f059e;
  box-shadow: none;
  color: #0f059e;
}
.dasboard-sub-header .content-right .search-box {
  margin-right: 20px;
  width: 100%;
}
.dasboard-sub-header .content-right input.form-control:hover,
.dasboard-sub-header .content-right input.form-control:active,
.dasboard-sub-header .content-right input.form-control:focus-within {
  box-shadow: none;
  border-color: #0f059e;
}
.dasboard-sub-header .content-right .add-new-box {
  text-align: center;
  min-width: 130px;
  cursor: pointer;
  font-weight: 700;
  transition: var(--transition);
}
.dasboard-sub-header .content-right .add-new-box:hover,
.dasboard-sub-header .content-right .add-new-box:focus {
  color: #753bbc;
}

.flex {
  display: flex;
  /* margin-top: 30px; */
}
.label-flex {
  width: inherit;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #1b1a1a;
}
.fullfill {
  vertical-align: middle;
  border-style: none;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 35px;
  top: 34px;
}
.custom-date-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  padding: 10px;
}
.space-between {
  justify-content: space-between;
}

.flex-dir-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-dir-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.no-wrap {
  flex-wrap: nowrap;
}
.page-tile {
  max-width: 400px;
  min-width: 100%;
  background: #efefef;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 5px;
  min-height: 130px;
  width: 33.333%;
  cursor: pointer;
  position: relative;
  transition: var(--transition);
}

.page-tile .status {
  position: absolute;
  right: 10px;
  bottom: 8px;
  background: #0080001f;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #0e860e;
}
.page-tile .status.inactive {
  color: #f4433694;
  background: #f443361c;
}

.page-tile .action-btn-row {
  display: none;
  position: absolute;
  bottom: 8px;
  opacity: 0;
  transition: var(--transition);
}
.page-tile .action-btn-row ul li {
  border: none;
  padding: 0px;
  font-family: "Gilroy-Medium";
  margin: 2.5px;
  margin-right: 5px;
}
.page-tile .action-btn-row ul li:hover,
.page-tile .action-btn-row ul li:focus {
  border: none;
}

.page-tile:hover .action-btn-row,
.page-tile:focus .action-btn-row,
.page-tile:active .action-btn-row {
  display: block;
  opacity: 1;
}
.page-tile:hover,
.page-tile:focus {
  background: #d2d2d2;
}
/* bredcrumbs */

.info-row-level-1 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 12px;
}
.info-row-level-1 .breadcrumb {
  margin-bottom: 0;
  padding: 0.5rem 1rem;
}
.info-row-level-1 .last-updated {
  padding: 0.5rem 1rem;
}

.info-row-level-2 {
  position: relative;
}
.info-row-level-2 ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.info-row-level-2 ul.action-items {
  flex-wrap: nowrap;
}
.info-row-level-2 ul.action-items li {
  margin: 2px;
  padding: 5px;
  color: #777777;
  min-width: 50px;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}
.info-row-level-2 ul.filter-items {
  /* margin: 0px 10px; */
  padding-top: 3px;
  padding-bottom: 1px;
}

.flex-align-ends {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.info-row-level-2 ul.action-items li.date-col {
  padding-top: 2px;
  padding-bottom: 2px;
  width: 270px;
}
.date-range-picker {
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  border: 0px solid #ccc;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 20px;
}

.date-range-picker .range-text {
  display: block;
  font-size: 12px;
  line-height: 1;
}
.date-range-picker .range {
  font-size: 12px;
  font-weight: 700;
}

.fontWeight {
  font-size: 16px;
  font-weight: bolder !important;
}

.date-range-picker .clander-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1px;
}

/* side widget nav */
.side-nav {
  position: fixed;
  top: 70px;
  z-index: 500;
  width: 230px;
  height: 100%;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  background: #fff;
  padding: 0px;
  /* box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08) */
  bottom: 0;
}
.side-nav .widget-title {
  text-align: center;
  font-family: "Gilroy-Medium";
  color: $base-color;
  padding: 10px;
  padding-top: 16px;
}
.builder_window .side-nav-container {
  position: relative;
  flex: 0 0 auto;
  width: 425px;
  border: 2px solid #d5dfe6;
  transition: width 0.4s;
  /* */
  height: calc(100% - 50px);
  top: 0;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
}
/* .side-nav::after {
//     pointer-events: none;
//     content: "↓";
//     font-size: 14px;
//     position: absolute;
//     height: 22px;
//     width: 22px;
//     text-align: center;
//     bottom: 2px;
//     right: -2px;
//     z-index: 2;
//     background-color: #efefef;
// }  */
.bg-clear {
  background-color: transparent;
}

.title-img span {
  font-size: 20px;
}
.title-img span small {
  display: block;
  font-size: 12px;
}

.side-nav ul {
  list-style: none;
  padding: 15px;
  margin: 0;
}
.side-nav ul li {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border-bottom: 1px solid #dedede;
  font-family: Gilroy-Medium;
}
.side-nav ul li.active {
  color: $base-color;
  border-left: 4px solid #0056bb;
  background: #e2e2e2;
}

#side-nav-accordionMenu .panel-default > .panel-heading.active {
  color: $base-color;
  border-color: #bbbbbb;
  background: #e2e2e2;
}
#side-nav-accordionMenu .panel-default > .panel-heading {
  background: #fff;
}
#side-nav-accordionMenu .panel-default {
  background: #fff;
}
#side-nav-accordionMenu .panel-default > .panel-heading {
  .panel-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #303030;
    padding: 9px 15px;
    cursor: pointer;
  }
  .panel-title.active {
    border-left: 4px solid $base-color;
    background: #eaf4ff;
  }
}

.side-nav ul li:hover,
.side-nav ul li:active,
.side-nav ul li:focus {
  background: #e6f3ff;
  cursor: pointer;
}
.side-nav ul li .icon {
  width: 20px;
  text-align: center;
}
.side-nav ul li .text {
  width: 100%;
  padding-left: 6px;
}
.side-nav ul li .drag {
  width: 24px;
  text-align: center;
}
.padd-left {
  /* padding: 15px; */
  padding-left: 250px !important;
}
.builder {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}
.page-titles {
  background: #fff;
  margin: 0 -30px 30px;
  padding: 15px;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}
.custom_group {
  padding: 16px;
  border: 0;
  max-width: 1200px;
  margin-bottom: 14px;
}
.custom_input {
  border: none;
  border-bottom: 1px solid #bcd8dc;
  border-radius: 0;
  color: #00bcd4;
  background-color: transparent;
}
.form-check .ext_link {
  margin-top: 14px;
}

.custom_input:hover,
.custom_input:focus,
.custom_input:active,
.custom_input:visited,
.custom_input:focus-within {
  background-color: #fff;
  border-color: #0a96d3;
  outline: 0;
  box-shadow: none;
  background-color: transparent;
}

.custom_group .form-check {
  position: relative;
  display: flex;
  padding-left: 1.25rem;
  justify-content: flex-start;
  margin-left: 30px;
}
.material-icons {
  font-family: "Gilroy-Medium";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
}
.blue {
  color: #16abd6;
}
#preview_screen {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: calc(100% - 50px);
  padding: 14px;
  margin-left: 10px;
  background-image: linear-gradient(to left, #d7e1e9, #d2dce2);
}
#preview_screen {
  .col-6,
  .col-12 {
    padding: 0px;
  }
  .builder-border-default {
    border: none;
    max-width: 100%;
  }
}
.custom-container {
  min-height: 100px;
  border: 1px solid #000;
}

.widget {
  /*border-bottom: 1px solid #292997;
  background: #272798;
  color: #fff;
  font-family: gilroy-medium;
  box-shadow: 0px 8px 15px rgba(39, 39, 152, 0.41);
  padding: 14px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px; */
  color: #272798;
  margin-top: 20px;
  margin-bottom: 20px;
}
.widget:before {
  /*
    content: '';
    background: red;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    */
}
.widget-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.widget-nav ul li {
  display: flex;
  cursor: grab;
  margin-left: 1px;
  margin-right: 1px;
  padding-right: 4px;
  padding-left: 4px;
  border-right: 1px solid #ffffff3d;
  transition: var(--transition);
  padding-top: 3px;
  padding-bottom: 3px;
}
.widget-nav ul li:last-child {
  border-right: none;
}
.widget-nav ul li .icon {
  padding: 4px;
}

.widget-nav ul li .icon {
  padding: 4px;
}
.widget-nav ul li .text {
  padding: 4px;
}
.widget-nav ul.item-align-end {
  justify-content: flex-end;
}
.widget-nav ul.action-btn-row li:hover,
.widget-nav ul.action-btn-row li:focus,
.widget-nav ul.action-btn-row li:active {
  background: #272797;
  border-radius: 4px;
  color: #fff;
}

.widget-nav .action-btn-row li {
  cursor: default;
}
.widget-nav .widget-flex-box {
  display: flex;
  justify-content: space-between;
}
.widget-nav .widget-flex-box ul li {
  padding-bottom: 0;
  padding-top: 0;
}
.widget-nav .widget-flex-inline {
  display: inline-flex;
}
.widget-nav .view-types {
  font-weight: normal;
  color: #4e8abe;
  text-shadow: none;
  border: 1px solid #b5c6d2;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: #f0f5f8;
  background-image: linear-gradient(to bottom, #ffffff, #dae5ed);
  background-repeat: repeat-x;
  border-radius: 4px;
  margin-left: 18px;
}
.widget-nav .view-types li.active-view {
  color: #4e8abe;
  background-color: #d9e6ef;
  *background-color: #d5e4eb;
  background-image: -moz-linear-gradient(top, #d5e4eb, #deeaf6);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#d5e4eb),
    to(#deeaf6)
  );
  background-image: -webkit-linear-gradient(top, #d5e4eb, #deeaf6);
  background-image: -o-linear-gradient(top, #d5e4eb, #deeaf6);
  background-image: linear-gradient(to bottom, #d5e4eb, #deeaf6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd5e4eb', endColorstr='#ffdeeaf6', GradientType=0);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 61, 99, 0.53);
  -moz-box-shadow: inset 0 0 3px rgba(0, 61, 99, 0.53);
  box-shadow: inset 0 0 3px rgba(0, 61, 99, 0.53);
}
.widget-nav ul.view-types li {
  display: flex;
  cursor: pointer;
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 4px;
  padding-left: 4px;
  border-right: none;
  transition: var(--transition);
  padding-top: 0px;
  padding-bottom: 0px;
}
.widget-nav ul.view-types li:hover,
.widget-nav ul.view-types li:focus,
.widget-nav ul.view-types li:active {
  color: #4e8abe;
  background-color: transparent;
  background-color: #e0eef8;
  *background-color: #f2f9ff;
  background-image: -moz-linear-gradient(top, #f2f9ff, #c6ddee);
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#f2f9ff),
    to(#c6ddee)
  );
  background-image: -webkit-linear-gradient(top, #f2f9ff, #c6ddee);
  background-image: -o-linear-gradient(top, #f2f9ff, #c6ddee);
  background-image: linear-gradient(to bottom, #f2f9ff, #c6ddee);
  background-position: center top;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f9ff', endColorstr='#ffc6ddee', GradientType=0);
}
.default-border:hover,
.default-border:active,
.default-border:focus {
  border-color: #272798;
}
.section-header {
  position: absolute;
  background: #00bcd438;
  font-size: 12px;
  right: 2px;
  top: 2px;
  transition: var(--transition);
  display: none;
}
.tab_container_block {
  display: flex;
}
.tab_container_block .icon {
  background: #e4e4e4;
  padding: 10px;
  border-radius: 4px;
}
.tab_container_block .widget_item {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
}

/*
.default-border:hover>.section-header, .default-border:focus>.section-header, .default-border:active>.section-header{
	display:block;
	opacity:1;
}
*/
.section-header ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.section-header ul li {
  padding: 6px;
  cursor: pointer;
  transition: var(--transition);
}
.section-header ul li:hover,
.section-header ul li:focus {
  background: #00bcd4;
  color: #fff;
}
.section-header ul li:last-child {
  border-left: 2px solid #fff;
}
.dashboard-item {
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
.dashboard-item .chart-icon {
  height: 40px;
  width: 40px;
  background: #0a036d59;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #fff;
  display: block;
  margin: auto;
}

.expandedSearchBar {
  min-width: 14rem !important;
  justify-content: flex-end !important;
}

.recommendations-table tr td,
.recommendations-table tr th {
  vertical-align: top;
}
.recommendations-table thead tr th:first-child {
  width: 30%;
}
.recommendations-table thead tr th:nth-child(3) {
  width: 13rem;
}
.recommendations-table thead tr th:nth-child(4) {
  width: 15rem;
}
.recommendations-table .recommendations-img {
  max-width: 60px;
}

.recommendations-table .flex-dir-col h4 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  color: #000000;
}
.recommendations-table .flex-dir-col h4 a {
  color: #000000;
  text-decoration: none;
}
#pageDropDown {
  background-color: $base-color;
  color: #fff;
  padding: 5px;
}
.builder-border-default {
  min-height: 10rem;
}
.view_builder_default {
  position: absolute;
  height: 100%;
  /* overflow: hidden;*/
  left: 0;
  right: 0;
  box-sizing: border-box;
}
.builder_window {
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
  padding: 0 18px 18px;
  margin-top: 6px;
}
#view_builder {
  width: 100%;
  overflow: hidden;
  position: relative;
  /**/
  height: calc(100% - 50px);
  padding: 14px;
  margin-left: 10px;
  border: 2px solid #d5dfe6;
}
#view_builder_content {
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  height: 100%;
}
.dropGround div {
  min-height: 2rem;
  border: 1px dashed #272798;
  padding: 1rem;
  position: relative;
}
.projectName {
  color: #303030;
  font-size: 20px;
  line-height: 43px;
  font-weight: bold;
}
.dropGround .sections {
  height: 40rem;
  width: 90%;
}
.sku {
  font-size: 12px;
  line-height: 16px;

  color: #747474;
}
.recommendations-table .flex-dir-col .sku-link i {
  font-size: 12px;
  color: #8c8c8c;
  cursor: pointer;
  margin-left: 8px;
}

.recommendations-table .remove {
  text-align: center;
  color: #8c8c8c;
  cursor: pointer;
}
.recommendations-table .recommendations-type {
}
.recommendations-table .recommendations-type {
  list-style: none;
  padding: 0;
  margin: 0;
}
.recommendations-table .recommendations-type li {
  display: inline-block;
  border: 1px solid #4a4a4a;
  margin: 5px;
  padding: 4px 12px;
  border-radius: 16px;
  transition: var(--transition);
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}

.recommendations-table .recommendations-type li:hover,
.recommendations-table .recommendations-type li:focus,
.recommendations-table .recommendations-type li:active {
  background: #fff;
  color: #444;
}

#recommendations-modal .modal-desc {
  font-size: 12px;
  padding-left: 20px;
}
#recommendations-modal .flex-header {
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
#recommendations-modal .bg-grey {
}
#recommendations-modal .status {
  display: inline-flex;
  position: relative;
  background: #f1f1f1;
  padding: 4px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #0d3974;
  border-radius: 10px;
  font-size: 12px;
}
#recommendations-modal .status .status-label {
  margin-right: 10px;
  border-right: 2px solid #000;
  padding-right: 10px;
  color: #0d3974;
}

#recommendations-modal .status .status-type {
  color: #007bff;
}
#recommendations-modal .status .status-edit {
  cursor: pointer;
  color: #0d3974;
  margin-left: 10px;
}

#recommendations-modal .modify-status {
  background: #f1f1f1;
  padding: 20px;
  border-top: 1px solid #0d39742e;
  border-radius: 0;
  margin-top: 4px;
  font-size: 12px;
  position: relative;
}
.custom-action-btn {
  margin: 4px;
  background-color: transparent;
  color: #000;
  font-size: 0.9rem;
}
#popup-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #f74c4c;
  cursor: pointer;
}
.custom-action-btn.btn-primary {
  color: #0062cc;
}
.custom-action-btn.btn-primary:hover,
.custom-action-btn.btn-primary:focus,
.custom-action-btn.btn-success:hover,
.custom-action-btn.btn-success:focus,
.custom-action-btn.btn-danger:hover,
.custom-action-btn.btn-danger:focus {
  color: #fff;
}
.custom-action-btn.btn-success {
  color: #1e7e34;
}

.custom-action-btn.btn-danger {
  color: #bd2130;
}

.pbi-img {
  max-width: 90%;
  margin: auto;
  margin-top: 50px;
}
.otherRec {
}
.heading-6 {
  margin-top: 20px;
  font-size: 16px;
  background: #f1f1f1;
  padding: 10px;
  text-align: center;
  font-weight: 700;
}
.otherRec ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 80px;
}
.otherRec ul li {
  margin: 0;
  border: 1px solid #9c9c9c;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 10px;
}

#accordionMenu,
.accordionMenu {
  max-width: 100%;
  max-width: 100%;
}
#filter-popup .modal-header {
  background: #007bff;
  color: #fff;
}

#accordionMenu h4,
.accordionMenu h4 {
  font-size: 1rem;

  margin-bottom: 2px;
}
#accordionMenu h4 a,
.accordionMenu h4 a {
  color: #4b5158;
}
#accordionMenu ul,
.accordionMenu ul {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  background: #fbfbfb;
}
#side-nav-accordionMenu ul {
  display: block;
  padding: 8px 2px;
  text-decoration: none;
}

#accordionMenu ul li,
.accordionMenu ul li {
}
#accordionMenu ul li a,
.accordionMenu ul li a {
  color: #000000;
}

.panel-body {
  padding: 0 !important;
}

.panel-group .panel + .panel {
  margin-top: 0;
  border-top: 0;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  color: #333;

  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  background: #eeeeee;
}

.panel-default > .panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: transform 0.25s linear;
  -webkit-transition: -webkit-transform 0.25s linear;
  font-size: 14px;
  top: 50%;
  position: absolute;
  right: 12px;
  transform: translateY(-50%);
}

.panel-default > .panel-heading a[aria-expanded="true"] {
  background-color: #eee;
}

.panel-default > .panel-heading a[aria-expanded="true"]:after {
  content: "\e113";
}

.panel-default > .panel-heading a[aria-expanded="false"]:after {
  content: "\e114";
}
.mr-b {
  margin-bottom: 30px;
}

.strategies-table {
  max-width: 98%;
  margin: auto;
  padding: 10px;
}

.strategies-table tr td,
.strategies-table tr th {
  vertical-align: middle;
}
.strategies-col-sno {
  width: 90px;
}

#side-nav-accordionMenu .panel-heading h4.panel-title {
  padding-top: 5px;
  padding-bottom: 4px;
  font-family: "Gilroy-Medium";
}

#side-nav-accordionMenu
  .panel-default
  > .panel-heading
  a[aria-expanded="true"] {
  background-color: transparent;
}
#side-nav-accordionMenu .panel-collapse ul {
  border: 2px solid #949494;
}
#side-nav-accordionMenu .panel-collapse li {
  position: relative;
}

.custom_group .custom_label {
  padding-left: 10px;
}

#side-nav-accordionMenu .panel-collapse ul li a .num-val {
  position: absolute;
  right: 4px;
  font-size: 12px;
  color: #1a73e8;
  background: #e2e2e2;
  padding: 4px;
}
#recommendations-modal {
  display: block;
}

.rdrDateRangePickerWrapper {
  position: absolute;
  right: 14rem;
  top: 3rem;
  border: 1px solid #f1f1f1;
}
.pagination .page-item a {
  background-color: $base-color !important;
  color: #fff;
}

.error {
  color: red;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}

.goBackBanner {
  padding: 10px 0px;
  padding-left: 1rem;
  background: $base-color;
  color: #fff;
  margin: 0;
  align-items: center;
  justify-content: flex-start;
}

.search-wrapper {
  display: flex !important;
  justify-content: flex-end !important;
}
#footer-buttons button {
  display: inline !important;
}
.btn {
  text-decoration: none;
}
.save-list {
  padding: 0 !important;
}

.save-list .dropdown-menu {
  padding: 0;
  min-width: 14rem;
  transform: none !important;
  top: 30px !important;
  left: -10rem !important;
}
.header {
  font-size: 20px;
  font-weight: 500;
  margin: 1px 0 0 0;
  padding: 20px 0;
  font-family: Gilroy-Medium;
  color: #050050;
  line-height: 32px;
  letter-spacing: -0.44px;
  cursor: pointer;
}
.save-list .header {
  padding: 10px;
  color: #fff;
  background-color: #4b5158;
  font-size: 18px;
  line-height: 24px;
}
.save-list .body {
  padding: 10px;
}

.saved-filters {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
}

.blue-heading {
  color: #007cf6;
}
.orange-heading {
  color: #ffa800;
}
.btn-link {
  background-color: $background-color;
  color: #303030;
  text-decoration: none !important;
}
.btn-link.focus,
.btn-link:hover {
  /* color:#303030;
   text-decoration: none !important;
  */
}

.pagination {
  margin-top: 25px;
}
.pagination span {
  cursor: pointer;
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
}

.pagination span.active {
  background-color: #1a73e8;
  color: #fff !important;
  border: 1px solid #1a73e8;
}
.loading_wrapper {
  text-align: center;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.disabledForAnalyst {
  pointer-events: none;
  color: #9e9e9e !important;
}
.w-100 {
  width: 100%;
}

.addNewVersion {
  text-align: center;
}

/*
.addNewVersion{
  position: fixed;
  text-align: center;
  width: 170px;
  bottom: 0;
  left: 0;
  background: #e4e4e4;
  font-weight: 600;
}
*/
.ck-editor__editable {
  max-height: calc(100vh - 310px) !important;
  max-width: calc(90vw - 180px) !important;
  height: calc(100vh - 310px) !important;
  width: calc(90vw - 180px) !important;
  padding: 0 20px !important;
}

.version-history {
  overflow-y: scroll;
  max-height: 600px;
}

.month-selector {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--radial-background);
  border-radius: 10px;
  display: grid;
  /* grid-template-areas: "1 2 3" "4 5 6" "7 8 9" "10 11 12"; */
  grid-template-columns: repeat(3, 1fr);
}

.selectable-month {
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border: 1px solid rgb(214, 214, 219);
  font-size: 12px;
}

.selectable-month:hover {
  background: #76b2f7;
}

.selected-month {
  background: #4a90e2;
  color: white;
}

.month-selector span:nth-child(1) {
  border-top-left-radius: 10px;
}

.month-selector span:nth-child(3) {
  border-top-right-radius: 10px;
}

.month-selector span:nth-child(10) {
  border-bottom-left-radius: 10px;
}

.month-selector span:nth-child(12) {
  border-bottom-right-radius: 10px;
}

.recommendation-value {
  height: 24px;
  background: #0056bb;
  border-radius: 20px;
  padding-right: 15px;
  padding-left: 15px;
  display: inline-block;
  color: white;
  text-align: center;
  font-weight: 700;
  min-width: 80px;
}

.amount {
  background-color: #303030 !important;
}

.recommendation-value.actioned {
  background-color: #329958 !important;
}

.clickable-span {
  color: #1563ff;
  font-weight: bold;
  cursor: pointer;
}
.clickable-span.actioned {
  color: #329958 !important;
}
.recommendation-inactive {
  background-color: #efecec;
}

.recommendation-border-top {
  border-top: 1px solid #e5e1df;
}
.row-highlighter {
  background-color: #eae9f7 !important;
}

.row-highlighter-green {
  background-color: #e2f8ea !important;
}

.inactive-section {
  background-color: #f5f5f5 !important;
  border: 1px solid #979797;

  border-radius: 8px;
}

.recommendation-header {
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
  width: 100%;
}
.action-button {
  background: #26a8f6;
  font-weight: bold;
  color: white;
  margin-left: 5px;
}
.action-button-clicked {
  background: #f5f5f5 !important;
  color: #1563ff !important;
  margin-left: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 0px !important;
}
/*.new-recomm ::-webkit-scrollbar {
  width: 7px;
}
.new-recomm ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #979797;
  border-radius: 11px;
}
.new-recomm ::-webkit-scrollbar-thumb {
  border-radius: 11px;
  box-shadow: inset 0 0 6px #979797;
}*/
#tooltip-disabled > .tooltip-inner {
  background-color: #303030;
  color: white;
  z-index: 999999;
  position: relative;
  border: 1px solid #303030;
}

#tooltip-disabled > .tooltip-arrow {
  border-top: 5px solid #303030;
  z-index: 999999;
  position: relative;
}

.recomm-locations div.locations.active > div.recomm-col {
  background: #e8efff;
}

#primary-secondary-action {
  font-size: 16px;
  margin-right: 4px;
  background: rgb(86, 204, 130);
  font-weight: bolder;
  min-width: 25px;
  color: white;
  max-height: 25px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.year-selector {
  margin-left: 70px;
  border: 2px solid #cecccc;
  margin-bottom: 4px;
}

.full-screen-height {
  height: 95vh;
}

.bookmark-screen-height {
  height: 75vh;
}

.wrappers-with-min-height {
  .tile-list {
    min-height: 300px;
  }
}

.MuiButtonBase-root {
  outline: none !important;
}

.tabList {
  .form-group {
    padding: 10px;
    background: #fff;
    border: 1px solid #ced4da;
    .form-check {
      padding-left: 1.85rem;
    }
    .form-check-input {
      margin-left: -1.85rem;
    }
  }
}

.addNewBoard {
  background-color: #ffffff !important;
  font-size: 20px;
  font-weight: 500;
  margin: 1px 0 0 0;
  padding: 20px 0;
  font-family: Gilroy-Medium;
  color: #050050;
  line-height: 32px;
  letter-spacing: -0.44px;
  cursor: pointer;
  margin: 0 0 10px !important
}

.alert-container {
  height: 95vh;
  min-height: 95vh;
  background: white;
  /*

  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  height: auto;
    max-height: fit-content; */
}

.alert-section {
  width: inherit;
  height: inherit;
  background: #f9f9f9;
}

.alert-create-section {
  display: flex;
  flex-wrap: wrap;
}

.alertBtn {
  background-color: #f4f5f6;
  color: #0f059e;
  margin-right: 10px;
  height: 40px;
  margin-bottom: 10px;
  display: inline-flex;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.alertCheckBox {
  height: 15px;
}

.alert-search {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  height: 35px !important;
  border: 1px solid #555 !important;
  border-radius: 2px !important;
  display: block !important;
  padding: 9px 4px 9px 40px !important;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.dateTimePicker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.userFilType {
  line-height: 28px;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
}

iframe {
  border: 0;
}

.cursor {
  cursor: pointer;
}

p {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  background-size: cover;
  margin: 0;
  font-weight: 400;
  line-height: 1;
  font-size: 1rem;
  padding: 1rem;
}

.verticalAlign {
  width: 90vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.line-height {
  line-height: 3;
}

.taleft {
  text-align: left;
}

.ta-end {
  display: flex;
  justify-content: end;
}

.justify-end-content {
  justify-content: end;
}

.user-card-container {
  max-height: 450px;
  height: 450px;
  overflow-y: auto;
  box-sizing: border-box;
}

.user-card-container:after {
  content: "";
  display: table;
  clear: both;
}

.user-card-box {
  padding-right: 5px !important;
  padding-left: 5px !important;
  margin: 10px 0px;

  height: 85px;
  max-height: 95px;
  box-sizing: border-box;
  float: left;
  cursor: pointer;
}

.user-card {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  padding: 10px 10px;
  box-sizing: border-box;
  border: 1px solid #acaaaa;
}

.user-name {
  font-size: 1.3rem;
  font-family: "Gilroy-Medium";
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin: 0;
  text-align: left;
}

.user-email {
  font-size: 1.1rem;
  font-family: "Gilroy-Medium";
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  margin-left: 0px;
  text-align: left;
}

.user-country {
  font-size: 1rem;
}

.user-kcId {
  font-size: 1rem;
}
.user-id span {
  font-weight: 500;
}

.display-content {
  display: contents;
}
.greenCheck img {
  width: 15px;
  height: 15px;
}

.select-all-user {
  display: flex;
  justify-content: initial;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 2.7;
}

.select-all-user input[type="checkbox"] {
  width: 22px;
  height: 17px;
  margin: 5px 5px;
}

.position {
  position: absolute;
  top: 50%;
  left: 50%;
}

.releaseSubCategory {
  padding: 10px 0 0;
  margin: 0;
  background-color: #f4f5f6;
  color: #0f059e;
  line-height: 1;
  cursor: pointer;
}

.releaseSubCategory span {
  border: 1px solid #0f059e;
  padding: 5px;
}

.label-sub-category {
  font-size: 12px;
  margin: 6px 0 0;
  color: #000000;
}

.iconBox {
  border: 1px solid #ced4da;
  height: 30px;
  width: 30px;
  margin: 0 0 0 5px;
  display: flex;
  justify-content: center;
}

.createCancel {
  display: flex;
  flex-direction: row;
}
.createCancel div {
  background-color: #b9002e !important;
}

.createCancel div + div {
  background-color: #0056bb !important;
}

.iconBox svg {
  margin-left: 0px !important;
  margin-top: 4px !important;
  font-size: 20px;
  color: white;
}

.label-flex-sm {
  width: inherit;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: #1b1a1a;
}

.removeNotifications svg {
  width: 20px;
  height: 20px;
}

.loaderPosition {
  position: absolute;
  left: 0;
  width: 100%;
}

.waitAlignment {
  position: absolute;
  left: 0;
  margin-top: 0rem;
  font-size: 16px;
  width: 100%;
}

.disabled {
  font-weight: 100;
  opacity: 0.2;
}

.options {
  font-weight: 400;
  opacity: 1;
}

.label-Style {
  text-align: left;
  letter-spacing: 0px;
  color: #44554c;
  opacity: 1;
  width: inherit;
  display: flex;
  font-size: 14px;
  font-weight: 400;
}

.alertHeader {
  font-size: 20px;
  font-weight: 500;
  margin: 1px 0 0 0;
  padding: 20px 0;
  background: #ffffff;
  color: "#142C1F";
}

.ql-editor {
  min-height: 400px !important;
}
