.bold {
  color: #212529;
  font-weight: 700;
}
.admin-options {
  cursor: pointer;
}
.admin-options:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}
.btn-plain {
  border-radius: 0;
  margin-bottom: 0 !important;
  color: #303030 !important;
}
.btn-plain.active {
  color: #0f059e !important;
  border-bottom: 2px solid #0f059e;
}
.btn-plain:focus {
  outline: 0;
  box-shadow: none;
}
.btn-right-align{
   margin : 0 0 0  auto !important;
}
.dashboard-button-row {
  border-bottom: 0.1px solid #ddd;
}

.ck.ck-editor__editable_inline > :first-child {
  color: black;
}

.px-4 input {
  width: 10px;
  height: 10px;

  margin: 10px 6px 0px 12px;
}

.suggestion span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.suggestionsKeyword {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
}

.suggestionsKeyword li {
  padding: 5px 10px;
  margin: 5px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: 1px solid #555;
  border-radius: 2px;
  cursor: pointer;
}

.addGrouped {
  background: #ffffff;
  color: #0f059e;
  border: 1px solid;
  margin: 12px;
  font-size: 12;
  line-height: 0;
}

.reportOrder {
  /* background-color: #fff; */
  width: 100%;
}

.list-container {
  display: flex;
  font-size: 18px;
  /* background-color: #eee; */
  flex-direction: column;
  width: 100%;
}
.item-container {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
  margin: 1rem 0


}

.item-container label{
  margin: 6px;
  font-weight: bold;
}

.subReport-container label{
  margin: 6px;
  font-weight: bold;
}

.item-container-column{
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem 0 1rem auto;
}

.reOrder{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin: 0 0 0 auto;
}

.alignment{
  display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    margin: auto 0;
}

.arrangments{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    cursor: pointer;
}

.prettier{
  font-size: 20px;
  font-weight: 500;
  font-family: Gilroy-Medium;
  color: #050050;
  margin: 1px 0 0 0;
  padding: 20px 0;
  line-height: 32px;
  letter-spacing: -0.44px;
  cursor: pointer;
}

.tile-new{
  font-weight: 500;
  font-family: Gilroy-Medium;
  color: #050050;
}

.tile-new label{
  font-weight: bold;
}

.caretdown {
  background-Image: url("../../assets/icons/caretdown.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  cursor: pointer;
  pointer-events: none;
  left: 24rem;
  bottom: 0;
  height: 15px;
  width: 15px;
}

.arrangeSubMenu{
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
}

.clickReport{
  width: 75%;
}

.editReport{
  width: 25%;
    background: #0f059e !important;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    right: 0;
    color: white;
}

.waiting_Loader{
  text-align: center;
   display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.widthState33{
  width: 33%;
}